import { graphql } from "gatsby";
import * as React from "react";

import { Layout, Title, VideosContainer } from "../components/index";

export const data = graphql`
    query {
        videos: videosPageJson {
            featured {
                videos {
                    ...videoData
                }
            }
            videos {
                ...videoData
            }
        }
    }
`;

const Videos = ({ data: { videos }, ...props }) => {
    return (
        <Layout {...props}>
            <Title>Videos</Title>
            <VideosContainer videosList={videos} />
        </Layout>
    );
};

export default Videos;